<template>
	<div class="order_wrap">
		<v-container class="fixed_wrap">
			<div class="txt_box px-6">
				<p class="txt_body1">
					납부 방법을 선택해 주세요. <br />
					납부 금액은 총
					<span class="primary--text"> {{ recpInfos.amount | comma }} 원</span>
					입니다
				</p>
			</div>
			<div class="divider" v-if="payRecpList && payRecpList.length > 0"></div>
			<div
				class="wrap_section pa-6"
				v-if="payRecpList && payRecpList.length > 0"
			>
				<h3 class="mb-4">정기결제정보 목록에서 선택</h3>
				<template v-for="(recp, i) in payRecpList">
					<template v-if="recp.USE === 'N'">
						<div :key="i">
							<v-btn
								disabled
								depressed
								height="48"
								class="btn_row justify-start mb-1"
							>
								<span class="sub--text">{{ recp.AF_PAY_MTHD_NM }} :</span>
								<span class="small">
									{{ recp.AF_BANK_CD_NM }}
									&nbsp;
									{{
										recp.AF_PAY_MTHD === '11'
											? $options.filters.star(recp.AF_CARD_NO, 4)
											: $options.filters.star(recp.AF_CARD_NO, 6)
									}}
								</span>
							</v-btn>
							<p class="txt_warn_small">※ 즉시 이체가 불가능한 은행입니다.</p>
						</div>
					</template>
					<template v-else>
						<v-btn
							depressed
							height="48"
							class="btn_row justify-start mb-1"
							:key="i"
							@click="goRecp(recp)"
						>
							<span class="sub--text">{{ recp.AF_PAY_MTHD_NM }} :</span>
							<span class="small">
								{{ recp.AF_BANK_CD_NM }}
								&nbsp;
								{{
									recp.AF_PAY_MTHD === '11'
										? $options.filters.star(recp.AF_CARD_NO, 4)
										: $options.filters.star(recp.AF_CARD_NO, 6)
								}}
							</span>
						</v-btn>
					</template>
				</template>
			</div>
			<div class="divider"></div>
			<div class="wrap_section pa-6 mb-12">
				<h3 class="mb-4">기타 결제수단</h3>
				<v-btn
					depressed
					height="48"
					class="btn_row justify-start mb-1"
					@click="onCardClick"
				>
					<span class="small">신용/체크카드 </span>
				</v-btn>
				<!-- <BankCompanyVAccountBottom
					ref="vaccount"
					class="btn_all"
					title="은행 선택"
					v-model="vaccountBankCompany"
					@inner-click="handleInnerClick"
					@item-selected="handleVAccountBankCompanySelected($event, false)"
				>
					<template
						>{{ vaccountbankCompanyDisplayText }}<span class="btn_value"></span
					></template>
				</BankCompanyVAccountBottom> -->
				<v-btn
					depressed
					height="48"
					class="btn_row justify-start mb-1"
					@click="onInstantTransfer"
				>
					<span class="small">은행 즉시이체 </span>
				</v-btn>
				<!-- <v-btn
					depressed
					height="48"
					class="btn_row justify-start mb-1"
					@click="onComplexPay"
				>
					<span class="small">복합결제 (신용/체크카드+가상계좌) </span>
				</v-btn> -->
				<!-- <BankCompanyVAccountBottom
					ref="vaccountComplex"
					class="btn_all"
					title="은행 선택"
					:hideButton="true"
					v-model="vaccountBankCompany"
					@inner-click="handleInnerClick"
					@item-selected="handleVAccountBankCompanySelected($event, true)"
				/> -->
				<CustomRecpDialog ref="recpPop" />
			</div>
			<!-- <div class="bottom_box">
				<v-btn color="#F4F4F4" class="btn_col_2 col-12 sub" @click="back">
					이전
				</v-btn>
			</div> -->
			<span class="txt_body1" v-if="showPassInvalid">
				안내문자 발송된 고객정보와 PASS 인증된 고객정보가 다릅니다.
			</span>
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
import { fetchDecrypedPassVerifiedCust } from '@/apis/order.verify.api'
import {
	fetchPeriodicAccounts,
	fetchRecpType,
	bankInstantTransfer,
	// cardTransfer,
	vaccountInstantCreate
} from '@/apis/order.recp.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { chkAuthExpired } from '@/apis/order.ec.api'
export default {
	mixins: [filters],
	data() {
		return {
			vaccountBankCompany: '',
			vaccountbankCompanyDisplayText: '개인 가상계좌 입금',
			payRecpList: [],
			etcRecpList: [],
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '', //04:판매인 07:고객납부
				app: '' // 앱 구분
			},
			custSelf: false, // 고객 직접 입력 여부
			showPassInvalid: false
		}
	},
	async mounted() {
		// TODO ec-cust-complete 랜딩 후 이동하므로 값 세팅 불필요
		const param = {}
		param.userKey = sessionStorage.getItem('userKey')
		const { userKey } = param ?? { userKey: this.$route.query }
		const { decrypt } = this.$route.params
		const retInfo = this.$route.query.retInfo
		const orderId = sessionStorage.getItem('orderId')
		let custPassInfo = {}
		if (retInfo) {
			const res = await fetchDecrypedPassVerifiedCust({ retInfo, orderId })
			custPassInfo = res.resultObject
		} else {
			custPassInfo.name = decrypt.name
			custPassInfo.cellNo = decrypt.cellNo
		}
		// this.$log('custPassInfo', custPassInfo.name)
		// this.$log('custPassInfo', custPassInfo.cellNo)
		const { name: guideMsgName, phone: guideMsgPhone } = JSON.parse(
			sessionStorage.getItem('customer')
		)

		if (
			!custPassInfo.name === guideMsgName &&
			custPassInfo.cellNo === guideMsgPhone
		) {
			await this.$alert({
				message: '안내문자 발송된 고객정보와 PASS 인증된 고객정보가 다릅니다.'
			})
			return false
			// this.showPassInvalid = true
			// return true
		}
		this.$log('customer', guideMsgName)
		this.$log('customer', guideMsgPhone)
		// this.$log('custPassInfo', custPassInfo)
		// let customer = JSON.parse(sessionStorage.getItem('customer'))
		// this.$log('customer', customer)
		if (userKey) {
			const res = await chkAuthExpired({ userKey })

			if (res.resultObject.valid) {
				const info = res.resultObject.orderInfo
				const json = JSON.parse(info.orderInfoJson)

				this.$store.dispatch('common/setUserInfo', {
					codyNo: json.customer.codyNo,
					codyPhoneNo: json.customer.codyPhoneNo
				})

				this.$store.dispatch('verify/setMobOrderNo', info.id)

				this.$store.dispatch('verify/setCustomer', json.customer)

				this.$store.dispatch('pay/saveRecpAmt', json.RECP_AMT) // TODO 수납금액
				this.$store.dispatch(
					'pay/saveMonthAmtDc',
					json.MONTH_AMT_DC !== undefined ? json.MONTH_AMT_DC : 0
				) // 렌탈 할인금액
				this.$store.dispatch('inst/setOrderInfoResult', json.orderInfoResult)

				await this.saveWebOrderInfoAndInitData()
			} else {
				this.$router.push({ name: 'ec-cust-expired' })
			}
		} else {
			// 서명 후 바로 진입
			await this.saveWebOrderInfoAndInitData()
		}
	},
	methods: {
		async saveWebOrderInfoAndInitData() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']

			let webRes = await fetchWebOrderInfoById(mobOrderNo)
			let webParams = webRes.resultObject

			webParams.step = '04' // 수납
			webParams.statusCode = '105' // 수납입력
			this.custSelf = this.$store.getters['verify/custSelf']
			const recpAmt = this.$store.getters['pay/recpAmt']

			// 정기결제정보 목록
			let ssn = this.$store.getters['verify/ssn']
			if (ssn) {
				ssn = ssn.substr(2, ssn.length)
				let custType = this.$store.getters['verify/customerType']
				if (custType === 'L') {
					ssn = this.$store.getters['verify/bizNo']
				}
			}
			let name = this.$store.getters['verify/custName']
			let custType = this.$store.state['verify'].customer.custType
			this.recpInfos.name = name
			this.recpInfos.custType =
				custType === 'P' ? '1' : custType === 'L' ? '2' : custType
			this.recpInfos.ssn = ssn
			this.recpInfos.amount = recpAmt
			this.recpInfos.kunnr = this.$store.state['verify'].customer.custNo
			this.recpInfos.userId = this.$store.getters['common/codyNo']
			this.recpInfos.phone = this.$store.getters['verify/custPhone']
			this.recpInfos.zwebRecp = '07' //04:판매인 07:고객납부
			this.$store.dispatch('recp/setRecpInfos', this.recpInfos)

			let params = {
				kunnr: this.recpInfos.kunnr,
				zwebRecp: this.recpInfos.zwebRecp,
				userId: this.recpInfos.userId
			}
			let accRes = await fetchPeriodicAccounts(params)
			this.payRecpList = accRes.resultObject.data
		},
		async chkReturn(prm, rtn, tcObj) {
			this.$log('rtn:', JSON.stringify(rtn))
			if (rtn.TYPE === 'S') {
				// store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				fetchWebOrderInfoById(mobOrderNo).then(res => {
					let webParams = res.resultObject
					let order = JSON.parse(webParams.orderInfoJson)
					order = Object.assign(order, {
						recp: {
							recpParams: this.$store.getters['recp/recpParams'],
							recpInfos: this.$store.getters['recp/recpInfos'],
							isCustSelfPay: true,
							payRecp: this.$store.getters['recp/payRecp']
						}
					})

					if (prm.payMthd === 'BB' || prm.payMthd === '02') {
						order.recp.recpCardResult = tcObj
					} else {
						order.recp.recpBankResult = tcObj
					}

					webParams.step = '06' // 전자계약
					webParams.statusCode = '108' // 서명입력
					webParams.orderInfoJson = JSON.stringify(order)

					postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
						this.$log('postRes: ', JSON.stringify(postRes))
						// 전자서명 완료
						if (this.recpInfos.mthd === 'BB') {
							// 'AA' 은행 즉시 이체 // 'BB' 카드 이체
							this.$router.push({
								name: 'ec-cust-pass',
								query: { userKey: this.$route.query.userKey }
							})
						} else if (this.recpInfos.mthd === 'AA') {
							this.$alert({
								message:
									'일회성 납부금 이체는 계약서 서명 후 진행되니, 서명 전 잔액이 충분한지 확인해 주세요.<br/>"계속 진행하기"를 선택하여 전자 계약서 서명을 완료해 주세요.',
								okText: '계속 진행하기'
							}).then(x => {
								if (x) {
									this.$router.push({
										name: 'ec-cust-pass',
										query: { userKey: this.$route.query.userKey }
									})
								}
							})
						}
					})
				})
			} else {
				// 즉시이체 거부
				if (this.recpInfos.mthd === 'AA') {
					this.$store.dispatch('recp/setIsCustSelfPay', true)
					this.$router.push({
						name: 'recp-cust-uncomplete',
						params: { status: '106', msg: rtn.MESSAGE }
					})
				} else {
					const options = {
						title: '다음과 같은 사유로 납부가 거부됐습니다.',
						guide: '납부방법을 재선택 바랍니다.',
						contents: {
							거부사유: rtn.MESSAGE
						},
						okText: '납부방법 재선택',
						cancelText: ''
					}

					const popRes = await this.$refs.recpPop.open(options)

					if (popRes) {
						// 납부방법 재선택
					}
				}
			}
		},
		async goRecp(recp) {
			this.$store.dispatch('recp/setPayRecp', recp)
			this.$log(JSON.stringify(recp))
			let mthd = ''
			switch (recp.AF_PAY_MTHD) {
				case '11':
					mthd = 'AA' // 은행 즉시 이체
					break
				case '12':
					mthd = 'BB' // 카드 이체
					break
				case '16':
					mthd = '16' // 가상계좌 - 기타 결제
					break
			}

			this.recpInfos.mthd = mthd

			this.$log('recp.AF_PAY_MTHD, mthd:', recp.AF_PAY_MTHD, mthd)

			let params = {
				zwebRecp: this.recpInfos.zwebRecp,
				payMthd: mthd,
				kunnr: this.recpInfos.kunnr,
				okamt: this.recpInfos.amount,
				userId: this.recpInfos.userId
			}

			const typeRes = await fetchRecpType(params)
			let obj = {}
			this.$log(obj)
			if (mthd === 'AA') {
				// 은행즉시이체
				obj = typeRes.resultObject.data.TC_BKACCT[0]

				const options = {
					title: '다음 계좌를 통해 즉시이체를 요청합니다.',
					guide: '즉시 이체는 계약서 서명 후 진행됩니다.',
					contents: {
						예금주: recp.AF_OWNER_NM,
						생년월일: recp.AF_SSN_NO,
						납부방법: `${recp.AF_BANK_CD_NM} 
						${this.$options.filters.star(recp.AF_CARD_NO, 4)}`,
						납부금액: `${this.$options.filters.comma(this.recpInfos.amount)}원`
					},
					okText: '즉시이체',
					cancelText: '납부방법 재선택'
				}

				const popRes = await this.$refs.recpPop.open(options)

				if (popRes) {
					let prm = {
						bankc: recp.AF_BANK_CD,
						suacc: recp.AF_CARD_NO,
						resty: this.recpInfos.custType,
						resId: recp.AF_SSN_NO,
						depositor: this.recpInfos.name,
						okamt: this.recpInfos.amount,
						userId: this.recpInfos.userId,
						cellPhone: this.recpInfos.phone,
						kunnr: this.recpInfos.kunnr,
						mobOrderNo: this.$store.state['verify'].mobOrderNo,
						zwebRecp: this.recpInfos.zwebRecp,
						salesApp: this.$store.getters['common/app'],
						salesType: this.$store.getters['common/type']
					}

					// 은행즉시이체는 api 생략 - 원복
					const bankRes = await bankInstantTransfer(prm)
					const bankRtn = bankRes.resultObject.data.E_RETURN

					await this.chkReturn(
						prm,
						bankRtn,
						bankRes.resultObject.data.TC_BKACCT[0]
					)
				} else {
					// 납부방법 재선택
				}
			} else if (mthd === 'BB') {
				// 카드이체
				obj = typeRes.resultObject.data.TC_CARD[0]

				// if (popRes) {
				let prm = {
					zwebRecp: '00',
					payMthd: mthd,
					userId: this.recpInfos.userId,
					cardn: recp.AF_CARD_NO,
					avDat: recp.AF_EXPIRE_YM,
					okamt: this.recpInfos.amount,
					kunnr: this.recpInfos.kunnr,
					mobOrderNo: this.$store.state['verify'].mobOrderNo,
					name: this.recpInfos.name,
					depositor: this.recpInfos.name,
					ssn: this.recpInfos.ssn,
					restry: this.recpInfos.custType,
					bankc: recp.AF_BANK_CD,
					bankNm: recp.AF_BANK_CD_NM,
					phone: this.$store.getters['verify/custPhone']
				}

				this.$router.push({
					name: 'recp-cust-regcard',
					params: {
						prm: prm,
						recp: recp,
						amount: this.recpInfos.amount
					},
					query: { userKey: this.$route.query.userKey }
				})
			} else if (mthd === '16') {
				// 가상계좌 - 기타 결제
				const rtn = typeRes.resultObject.data.E_RETURN
				this.$log(rtn)
				obj = typeRes.resultObject.data.TC_VTACCT[0]

				if (rtn.TYPE === 'S' && rtn.CODE === '0000') {
					// 가상계좌 존재
					this.$store.dispatch('recp/setVAccountParams', {
						TC_VTACCT: typeRes.resultObject.data.TC_VTACCT[0],
						TO_DISPLAY: typeRes.resultObject.data.TO_DISPLAY,
						isNew: false,
						mthd
					})
					this.$router.push({ name: 'recp-cust-vaccount' })
				} else {
					this.$refs.vaccount.open()
				}
			}
		},
		onCardClick() {
			this.$router.push({
				name: 'recp-cust-card',
				query: { userKey: this.$route.query.userKey }
			})
		},
		onVAccountClick() {
			let recp = {
				AF_PAY_MTHD: '16'
			}
			this.goRecp(recp)
		},
		async handleInnerClick(complex) {
			this.$log('handleInnerClick complex: ', complex)

			// 납부(개인 가상계좌)
			if (complex) {
				// 복합
				const mthd = '90'
				this.recpInfos.mthd = mthd
				let params = {
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: mthd,
					kunnr: this.recpInfos.kunnr,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId
				}

				const res = await fetchRecpType(params)
				const rtn = res.resultObject.data.E_RETURN
				const TC_VTACCT = res.resultObject.data.TC_VTACCT

				if (
					rtn.TYPE === 'S' &&
					rtn.CODE === '0000' &&
					TC_VTACCT &&
					TC_VTACCT.length > 0
				) {
					// 복합 가상계좌 존재
					this.$store.dispatch('recp/setVAccountParams', {
						TC_VTACCT: res.resultObject.data.TC_VTACCT[0],
						TO_DISPLAY: res.resultObject.data.TO_DISPLAY,
						isNew: false,
						mthd
					})
					this.$router.push({ name: 'recp-cust-complex' })
				} else {
					this.$refs.vaccountComplex.open()
				}
			} else {
				let recp = {
					AF_PAY_MTHD: '16'
				}
				this.goRecp(recp)
			}
		},
		async handleVAccountBankCompanySelected(item, complex) {
			this.$log('complex: ', complex)
			this.vaccountbankCompanyDisplayText = item.text

			if (complex) {
				// 복합 가상계좌생성
				const mthd = '90'

				let prm = {
					userId: this.recpInfos.userId,
					kunnr: this.recpInfos.kunnr,
					bankc: item.code
				}

				const res = await vaccountInstantCreate(prm)
				const rtn = res.resultObject.data.E_RETURN
				if (rtn.TYPE === 'E') {
					this.$alert({ message: rtn.MESSAGE })
				} else if (rtn.CODE === '0000' || rtn.CODE === '1000') {
					// TYPE: S & CODE: 0000 성공 / TYPE: W & CODE: 1000 존재
					// 복합결제 화면으로 이동
					this.$store.dispatch('recp/setVAccountParams', {
						TO_VTINFO: res.resultObject.data.TO_VTINFO[0],
						isNew: true,
						mthd,
						bankc: item.code,
						bankName: item.text
					})
					this.$router.push({ name: 'recp-cust-complex' })
				}
			} else {
				// 가상계좌생성
				let prm = {
					userId: this.recpInfos.userId,
					kunnr: this.recpInfos.kunnr,
					bankc: item.code
				}

				const res = await vaccountInstantCreate(prm)
				const rtn = res.resultObject.data.E_RETURN
				if (rtn.TYPE === 'E') {
					this.$alert({ message: rtn.MESSAGE })
				} else if (rtn.CODE === '0000' || rtn.CODE === '1000') {
					// TYPE: S & CODE: 0000 성공 / TYPE: W & CODE: 1000 존재
					// 가상계좌납부 화면으로 이동
					this.$store.dispatch('recp/setVAccountParams', {
						TO_VTINFO: res.resultObject.data.TO_VTINFO[0],
						isNew: true,
						mthd: '16',
						bankc: item.code,
						bankName: item.text
					})
					this.$router.push({
						name: 'recp-cust-vaccount',
						query: { userKey: this.$route.query.userKey }
					})
				}
			}
		},
		onInstantTransfer() {
			this.$router.push({
				name: 'recp-cust-bank',
				query: { userKey: this.$route.query.userKey }
			})
		},
		onComplexPay() {
			this.handleInnerClick(true)
		},
		async back() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			const res = await fetchWebOrderInfoById(mobOrderNo)
			let webParams = res.resultObject
			let order = JSON.parse(webParams.orderInfoJson)
			order = Object.assign(order, this.$delete(order, 'recp'))
			webParams.orderInfoJson = JSON.stringify(order)
			webParams.step = '03' //결제정보
			webParams.statusCode = '104' //결제입력
			postWebOrderInfoById(mobOrderNo, webParams)
			this.$router.push({ name: 'order-pay' })
		}
	}
}
</script>
